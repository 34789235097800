import {
    getMonth,
    getYear,
    parseISO,
    format
} from "date-fns"

const handles_dates = {
    methods: {
        standardized_range_object (date_data) {
            if((typeof date_data === "object") && !(date_data instanceof Date) && (typeof date_data.from !== "undefined")) {
                if(!(date_data.from instanceof Date)) date_data.from = parseISO(date_data.from)
                if(!(date_data.to instanceof Date)) date_data.to = parseISO(date_data.to)
                return date_data
            }
            const date = (date_data instanceof Date) ? date_data : parseISO(date_data)
            return { from: date, to: date }
        },
        standardized_date (date_data) {
            if(!date_data) return null
            if(date_data && (date_data instanceof Date)) return date_data
            return parseISO(date_data)
        },
        month_for_date (date_data) {
            const date = (date_data instanceof Date) ? date_data : parseISO(date_data)
            return getMonth(date)
        },
        year_for_date (date_data) {
            const date = (date_data instanceof Date) ? date_data : parseISO(date_data)
            return getYear(date)
        }
    },
    computed: {
        months () {
            return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(month => {
                return { id: month, name: format(new Date(2021, month, 1), "MMMM") }
            })
        },
        years () {
            const current_year = this.year_for_date(new Date())
            const years = []
            for(let year = current_year; year++; year < (current_year + 5)) years.push({ id: year, name: format(new Date(year, 0, 1), "yyyy") })
            return years
        }
    }
}

export default handles_dates
