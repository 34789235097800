import is_page from "./IsPage"
import is_record_editor from "./IsRecordEditor"

const is_record_editor_page = {
    mixins: [is_page, is_record_editor],
    methods: {
        statused (status) {
            this.page_status = status
        }
    }
}

export default is_record_editor_page
