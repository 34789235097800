import { mapState } from "vuex"

const misc_utilities = {
    methods: {
        setting (id) {
            if(this.app_context && this.app_context.settings) return this.app_context.settings[id]
            return null
        },
        constants (group, id = null) {
            if(!!this.app_context && !!this.app_context.constants && !!this.app_context.constants[group]) {
                if(!id) return this.app_context.constants[group]
                return this.app_context.constants[group][id] || {}
            }
            return {}
        },
        is_beta_testing (record_or_active_beta_tests, beta_test_id) {
            const source = record_or_active_beta_tests ? (record_or_active_beta_tests.active_beta_tests || record_or_active_beta_tests) : []
            return !!source && Array.isArray(source) && (source.indexOf(beta_test_id) >= 0)
        }
    },
    computed: {
        ...mapState(["app_context"])
    }
}

export default misc_utilities
